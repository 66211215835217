import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Link } from 'react-scroll';
import Plx from "react-plx";

const parallaxData = [
    {
        start: 700,
        end: 1200,
        properties: [
            {
                startValue: 200,
                endValue: -50,
                property: "translateY",
            },
        ],
    },
];
const parallaxData1 = [
    {
        start: 800,
        end: 1300,
        properties: [
            {
                startValue: 250,
                endValue: -50,
                property: "translateY",
            },
            {
                startValue: 0,
                endValue: 1,
                property: "opacity",
            },

        ],
    },
];

const parallaxData3 = [
    {
        start: 900,
        end: 1500,
        properties: [
            {
                startValue: 0,
                endValue: 1,
                property: "opacity",
            },
            {
                startValue: 200,
                endValue: -50,
                property: "translateY",
            },
        ],
    },
];


export default function Services() {
    return (
        <div className="ServicePad b-sce" id="what">
            <CssBaseline />
            <Container style={{ maxWidth: '1300px', paddingBottom: '50px', padding: '0px' }}>

                <Box sx={{ flexGrow: 1 }}>
                    <Plx className="MyAwesomeParallax" parallaxData={parallaxData}>
                        <Typography variant="h3" className="pmf2" style={{ fontSize: '40px !important' }}>
                            WHAT WE DO
                        </Typography>
                    </Plx>
                    <Plx className="MyAwesomeParallax" parallaxData={parallaxData1}>
                        <p>Born from a <mark>decade</mark> of connection and creation with the <mark>global football community,</mark>
                            <br /> our solution sits at the intersection of insight, creativity and content where the
                            <br /> most powerful creators can influence <mark>4 billion football fans</mark> around the world.</p>
                    </Plx>
                    <Plx className="" parallaxData={parallaxData3}>
                        <Grid container xs={12} lg={12} spacing={2}>

                            <Grid xs={12} md={12} lg={4} className="">
                                <div className="what-but">
                                    <h3 style={{ padding: '0px' }}>Campaign strategy</h3>
                                    <p>Leveraging our unique tools <br />and insights on the modern <br />fan to inform strategies</p>
                                </div>

                            </Grid>

                            <Grid xs={12} md={12} lg={4}>
                                <div className="what-but">
                                    <h3 style={{ padding: '0px' }}>Creative execution</h3>
                                    <p>Designed by the most <br />awarded creative studio <br />for football activations</p>
                                </div>
                            </Grid>
                            <Grid xs={12} md={12} lg={4}>
                                <div className="what-but">
                                    <h3 style={{ padding: '0px' }}>Talent management</h3>
                                    <p>Audience-first creator <br />identification and selection <br />via our unique CRM</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} lg={12} spacing={2}>

                            <Grid xs={12} md={12} lg={6} className="">
                                <div className="what-but what-right">
                                    <h3 style={{ padding: '0px' }}>Campaign management</h3>
                                    <p>Media plans, guaranteed <br />targets, content optimisation, <br />comms and reporting</p>
                                </div>

                            </Grid>

                            <Grid xs={12} md={12} lg={6}>
                                <div className="what-but what-left">
                                    <h3 style={{ padding: '0px' }}>Client services</h3>
                                    <p>Full-service solution led <br />by partnership experts with <br />big brand experience</p>
                                </div>

                            </Grid>
                        </Grid>

                    </Plx>
                </Box>
            </Container>
            <div>
                <Link to="work" spy={true} smooth={true}><img className="hero-center arrow" style={{ paddingTop: '40px' }} src="../assets/icon-arrow.png" alt="curva" /></Link>
            </div>
        </div>

    );
}