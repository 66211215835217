import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';



const CreatorForm = () => {

    const [name, setName] = useState('');
    const [handle, setHandle] = useState('');
    const [email, setEmail] = useState('');



    const handleSubmit = (e) => {
        e.preventDefault();


        const serviceId = 'service_i9uxlrl';
        const templateId = 'template_a33ujpa';
        const publicKey = 'd3DUg0BtzWAGv_E6l';

        const templateParams = {
            from_name: name,
            Email: email,
            Handle: handle,
        };


        emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log('SUCCESS! Email sent and working', response);
                setName('');
                setHandle('');
                setEmail('');
                alert("Thank you for Submitting your information. We will be in touch shortly.");
            })
            .catch((error) => {
                console.error('Error sending email', error);
            });

    }


    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <FormControl sx={{ width: '32ch' }}>
                <OutlinedInput
                    placeholder="Name"
                    required
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />

                <OutlinedInput
                    placeholder="Handle"
                    required
                    type="text"
                    value={handle}
                    onChange={(e) => setHandle(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />

                <OutlinedInput
                    placeholder="Email"
                    type="email"
                    name='email'
                    value={email}
                    style={{ marginBottom: '20px' }}
                    onChange={(e) => setEmail(e.target.value)}
                    validation={{
                        required: {
                            value: true,
                            message: 'required',
                        },
                    }}



                />
                <Button variant="contained" className='con-but' type="submit">Submit</Button>
            </FormControl>

        </form>
    );
}
export default CreatorForm