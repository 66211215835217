import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import BrandForm from './brand-form';
import CreatorForm from './creator-form';
import Plx from "react-plx";

const parallaxData = [
    {
        start: 3200,
        end: 3500,
        properties: [
            {
                startValue: 300,
                endValue: -10,
                property: "translateY",
            },
        ],
    },
];



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Contact() {
    const [open, setOpen,] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    return (
        <div id="contact" className=" b-sce">
            <div id="cons"></div>
            <CssBaseline />
            <Container maxWidth="100%" className="NoPad">


                <Box sx={{ flexGrow: 1 }} className="contact">

                    <Grid container xs={12} lg={12} spacing={6}>

                        <Grid xs={12} md={12} lg={12} className="copy-center">
                            <Plx className="MyAwesomeParallax" parallaxData={parallaxData}>
                                <Typography variant="h3" className="" style={{ fontSize: '40px !important', color: '#000 !important', textAlign: 'center' }}>
                                    GET IN TOUCH
                                </Typography>
                            </Plx>
                            <Plx className="" parallaxData={parallaxData}>
                                <p className="hero-strip" style={{ fontSize: '28px' }}>to find out more about <mark style={{ backgroundColor: '#000', color: '#84c599' }}><span style={{ fontFamily: 'Rifton', fontStyle: 'normal' }}>CURVA</span></mark> <br />
                                    and our <mark style={{ backgroundColor: '#000', color: '#84c599' }}><i>full-service</i></mark> influencer <br />
                                    marketing solution.</p>
                                <Grid container xs={12} lg={12} spacing={4}>
                                    <Grid xs={6} md={6} lg={6} className="but-stack">
                                        <Box  >
                                            <Button variant="contained" onClick={handleOpen} className="l-con-but">brands</Button>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style} style={{ textAlign: 'center' }} className="form">
                                                    <div onClick={handleClose} className="close"></div>
                                                    <Typography id="modal-modal-title" variant="h3" component="h3">
                                                        I am a brand
                                                    </Typography>
                                                    <BrandForm />
                                                    <p style={{ paddingTop: '20px', fontSize: '12px' }}>By clicking submit you are consenting for COPA90 to store your data inline the <a href="https://www.copa90.com/privacy-policy" target='blank'>Privacy Policy</a></p>
                                                </Box>
                                            </Modal>
                                        </Box>
                                    </Grid>
                                    <Grid xs={6} md={6} lg={6} className="but-stack">
                                        <Box  >
                                            <Button variant="contained" onClick={handleOpen1}>creators</Button>
                                            <Modal
                                                open={open1}
                                                onClose={handleClose1}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style} style={{ textAlign: 'center' }} className="form">
                                                    <div onClick={handleClose1} className="close"></div>
                                                    <Typography id="modal-modal-title" variant="h3" component="h3">
                                                        I am a creator
                                                    </Typography>
                                                    <CreatorForm />
                                                    <p style={{ paddingTop: '20px', fontSize: '12px' }}>By clicking submit you are consenting for COPA90 to store your data inline the <a href="https://www.copa90.com/privacy-policy" target='blank'>Privacy Policy</a></p>
                                                </Box>
                                            </Modal>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Plx>

                        </Grid>


                    </Grid>

                </Box >
            </Container >
        </div >
    );
}