import React from "react";
import Marquee from "react-fast-marquee";



export default function LogoSlider() {
    return (
        <div className="slide-bg">
            <Marquee>

                <img
                    src="../assets/g-logo-1.png"
                    className="d-inline-block align-top slide-pic"
                    alt="lions logo"
                />

                <img
                    src="../assets/g-logo-2.png"
                    className="d-inline-block align-top slide-pic"
                    alt="clio logo"
                />

                <img
                    src="../assets/g-logo-3.png"
                    className="d-inline-block align-top slide-pic"
                    alt="the drum social logo"
                />

                <img
                    src="../assets/g-logo-4.png"
                    className="d-inline-block align-top slide-pic"
                    alt="clio logo"
                />

                <img
                    src="../assets/g-logo-5.png"

                    className="d-inline-block align-top slide-pic"
                    alt="the drum logo"
                />

                <img
                    src="../assets/g-logo-6.png"
                    className="d-inline-block align-top slide-pic"
                    alt="warc logo"
                />

                <img
                    src="../assets/g-logo-7.png"
                    className="d-inline-block align-top slide-pic"
                    alt="global logo"
                />

                <img
                    src="../assets/g-logo-8.png"
                    className="d-inline-block align-top slide-pic"
                    alt="digiday logo"
                />


            </Marquee>
        </div>
    );
};