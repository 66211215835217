import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


const BrandForm = () => {

    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const serviceId = 'service_i9uxlrl';
        const templateId = 'template_jd7dowh';
        const publicKey = 'd3DUg0BtzWAGv_E6l';

        const templateParams = {
            from_name: name,
            Email: email,
            Company: company,
            Location: location,
            How_can_we_help: message,
        };

        emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log('SUCCESS! Email sent and working', response);
                setName('');
                setCompany('');
                setEmail('');
                setLocation('');
                setMessage('');
            })
            .catch((error) => {
                console.error('Error sending email', error);
            });

    }




    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <FormControl sx={{ width: '32ch' }}>
                <OutlinedInput
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />

                <OutlinedInput
                    placeholder="Company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />

                <OutlinedInput
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />

                <OutlinedInput
                    placeholder="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />

                <TextField
                    id="outlined-multiline-flexible"
                    label="How can we help"
                    multiline
                    maxRows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ marginBottom: '20px' }}
                />

                <Button variant="contained" className='con-but' type="submit">Submit</Button>
            </FormControl>

        </form>
    );
}
export default BrandForm