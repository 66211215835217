import React, { FC, ReactElement } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';


export const Footer: FC = (): ReactElement => {
    return (
        <Container maxWidth="100%" style={{ backgroundColor: '#000' }} className="footer">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={12} lg={12}>
                        <Typography color="#fff" variant="p">
                            <i>POWERED BY</i>  <br />
                            <a href="https://www.copa90.com/" target="blank"><img
                                src="../assets/copa-logo.png"
                                width="320"
                                height="69"
                                className="d-inline-block align-top"
                                alt="Copa90 logo"
                                style={{ marginTop: '10px', marginBottom: '30px' }}
                            /></a>
                        </Typography>

                    </Grid>
                    <Grid container xs={12} md={12} lg={12} spacing={2} style={{ paddingBottom: '40px' }}>
                        <Grid xs={12} lg={12}>

                            <Box
                                id="category-a"
                                sx={{}}
                                className="footer-links"
                            >
                                <Grid container columnSpacing={4} sx={{ order: { xs: 3, sm: 3 }, display: 'inline-flex !important' }}>

                                    <Grid>
                                        <a href="https://www.copa90.com/privacy-policy" target="blank">Privacy Policy</a>
                                    </Grid>
                                    <Grid>
                                        <a href="https://www.copa90.com/terms-and-conditions" target="blank">Terms & Conditions</a>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} md={12} lg={12} spacing={2} style={{ display: 'block' }}>
                        <Box sx={{ color: '#fff' }}>
                            <span style={{ color: '#fff' }}><b>Address:</b> COPA90 20 Saint Thomas Street, London, SE1 9RS
                            </span>
                        </Box>
                    </Grid>
                    <Grid
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        sx={{ fontSize: '14px', color: '#fff', display: 'block' }}
                    >

                        <Grid sx={{ order: { xs: 2, sm: 1 } }}>
                            {`COPA90 2023. All Rights Reserved.`}
                        </Grid>

                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default Footer;