import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import CaseSlider from './case-slider';
import LogoSlider from './logo-slider2';


export default function Work() {
    return (
        <div id="work" className="TopPad b-sce">
            <CssBaseline />
            <Container maxWidth="100%" className="NoPad" >

                <Box sx={{ flexGrow: 1 }} >

                    <Grid container xs={12} lg={12} spacing={2}>

                        <Grid xs={12} md={12} lg={12}>
                            <Box sx={{ margin: '0px' }} className="work">
                                <CaseSlider />
                            </Box>

                            <LogoSlider />

                        </Grid>


                    </Grid>

                </Box>
            </Container>
        </div>
    );
}