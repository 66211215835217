import React, { useRef } from 'react';
import { Navbar, Footer, Hero, Services, Work, About, Contact } from "../components";

const Homepage = () => {


    return (
        <div >
            <Navbar />
            <Hero />
            <Services />
            <Work />
            <Contact />
            <Footer />
        </div>
    )
}

export default Homepage