import React, { useRef, Component } from "react";
import { Navigation, Autoplay, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default () => {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
    return (
        <>
            <Swiper
                // install Swiper modules
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 10500,
                    disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="swiper-new"
                style={{ height: 'auto' }}

            >
                <SwiperSlide>
                    <img
                        src="../assets/case-std-1.jpg"
                        width="100%"
                        className=" align-top std"
                        alt="Pepsi Duets"
                    />
                    <img
                        src="../assets/case-mob-1.jpg"
                        width="100%"
                        className=" align-top mob"
                        alt="Pepsi Duets"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src="../assets/case-std-2.jpg"
                        width="100%"
                        className=" align-top std "
                        alt="Tiktok Euro 2021"
                    />
                    <img
                        src="../assets/case-mob-2.jpg"
                        width="100%"
                        className=" align-top mob"
                        alt="Tiktok Euro 2021"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src="../assets/case-std-3.jpg"
                        width="100%"
                        className=" align-top std "
                        alt="Sports Direct"
                    />
                    <img
                        src="../assets/case-mob-3.jpg"
                        width="100%"
                        className=" align-top mob"
                        alt="Sports Direct"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src="../assets/case-std-4.jpg"
                        width="100%"
                        className=" align-top std"
                        alt="Budweiser"
                    />
                    <img
                        src="../assets/case-mob-4.jpg"
                        width="100%"
                        className=" align-top mob"
                        alt="Budweiser"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src="../assets/case-std-5.jpg"
                        width="100%"
                        className=" align-top std"
                        alt="Addidas"
                    />
                    <img
                        src="../assets/case-mob-5.jpg"
                        width="100%"
                        className=" align-top mob"
                        alt="Addidas"
                    />
                </SwiperSlide>
                <div className="autoplay-progress" slot="container-end" style={{ height: '1px' }}>
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                        <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                    <span ref={progressContent}></span>
                </div>
            </Swiper>
        </>
    );
};