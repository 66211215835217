import { Homepage, NotFound } from "./pages";
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='*' element={<NotFound />}></Route>
        <Route path='/' element={<Homepage />}></Route>
      </Routes>
    </div>
  );
}

export default App;
