import React, { useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-scroll';
import Plx from "react-plx";
import LogoSlider from './logo-slider';

const parallaxData = [
    {
        start: 0,
        end: 500,
        properties: [
            {
                startValue: 300,
                endValue: -90,
                property: "translateY",
            },
        ],
    },
];
const parallaxData1 = [
    {
        start: 200,
        end: 500,
        properties: [
            {
                startValue: 300,
                endValue: -80,
                property: "translateY",
            },
            {
                startValue: 0,
                endValue: 0,
                property: "translateX",
            },

        ],
    },
];
const parallaxData2 = [
    {
        start: 200,
        end: 500,
        properties: [
            {
                startValue: 0,
                endValue: 0,
                property: "translateX",
            },
            {
                startValue: 300,
                endValue: -80,
                property: "translateY",
            },
        ],
    },
];

const parallaxData3 = [
    {
        start: 400,
        end: 700,
        properties: [
            {
                startValue: 0,
                endValue: 1,
                property: "opacity",
            },
            {
                startValue: 300,
                endValue: -100,
                property: "translateY",
            },
        ],
    },
];




export default function Hero() {

    return (
        <div id="hero" style={{ backgroundColor: '#000', marginTop: '0px', color: '#fff' }}>
            <CssBaseline />
            <Container maxWidth="100%" style={{ padding: '0px' }}>
                <Box sx={{ height: '100vh', margin: '0px' }} className="hero">
                    <div><img className="hero-center hero-title" src="../assets/curva-logo-main.png" alt="curva" />
                        <img className="hero-center hero-sub" src="../assets/sub-logo.png" alt="curva" /></div>
                    <div id="h2" className="hero-center" style={{ bottom: '200px', position: 'absolute' }}></div>
                    <Link to="h2" duration={200} spy={true} smooth={true}><img className="hero-center arrow" src="../assets/icon-arrow-blk.png" alt="curva" /></Link>
                </Box>

                <Box sx={{ flexGrow: 1 }} className="HeroPad">
                    <Grid container xs={12} lg={12} spacing={6} style={{ paddingTop: '0px' }}>
                        <Grid xs={12} md={12} lg={12} className="">
                            <Plx className="MyAwesomeParallax" parallaxData={parallaxData} style={{ marginTop: '0px' }} >
                                <img className="hero-center" src="../assets/we-create.png" alt="curva images" style={{ maxWidth: '811px', width: '100%' }} />
                            </Plx>
                            <Plx className="MyAwesomeParallax" parallaxData={parallaxData1}>
                                <p>award-winning football influencer campaigns,
                                    <br /> amplified with ten years of proprietary data,
                                    <br /> <mark>which deliver results for the world's biggest brands.</mark></p>
                            </Plx>
                            <Plx className="MyAwesomeParallax" parallaxData={parallaxData2}>
                                <p>As the footballing landscape continues to evolve at
                                    <br /> lightning speed, fan behaviour runs in parallel. Being ahead
                                    <br /> of the curve isn't a luxury - it's become a necessity.
                                    <br /> <mark>And we know the field.</mark></p>

                            </Plx>

                        </Grid>
                    </Grid>
                    <Grid container xs={12} lg={12} spacing={2}>

                        <Plx className="MyAwesomeParallax" parallaxData={parallaxData3} >
                            <img className="hero-center" src="../assets/divider.png" alt="curva images" style={{ maxWidth: '145px', width: '100%', padding: '30px 0px 30px' }} />

                            <LogoSlider />

                        </Plx>

                    </Grid>
                </Box>

            </Container>
        </div>
    );
}