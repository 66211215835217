import { Navbar, Footer } from "../components";

export default function NotFound() {
    return (
        <div>
            <Navbar />
            <div className="">
                <h3>404 Error - Oops! You seem to be lost.</h3>
                <p>Here are some helpful links above and below to get you back to where you want to be</p>
            </div>
            <Footer />
        </div>
    )
}