import React from "react";
import Marquee from "react-fast-marquee";



export default function LogoSlider() {
    return (
        <div className="">
            <Marquee>

                <img
                    src="../assets/Logos/BBC.png"
                    className="d-inline-block align-top slide-pic"
                    alt="bbc logo"
                />

                <img
                    src="../assets/Logos/CHELSEA.png"

                    className="d-inline-block align-top slide-pic"
                    alt="cfc logo"
                />

                <img
                    src="../assets/Logos/PLAYSTATION.png"
                    className="d-inline-block align-top slide-pic"
                    alt="playstation logo"
                />

                <img
                    src="../assets/Logos/FEDEX.png"
                    className="d-inline-block align-top slide-pic"
                    alt="fedex logo"
                />

                <img
                    src="../assets/Logos/EA.png"
                    className="d-inline-block align-top slide-pic"
                    alt="ea logo"
                />

                <img
                    src="../assets/Logos/SPORTS_DIRECT.png"
                    className="d-inline-block align-top slide-pic"
                    alt="sports direct logo"
                />

                <img
                    src="../assets/Logos/PUMA.png"
                    className="d-inline-block align-top slide-pic"
                    alt="puma logo"
                />

                <img
                    src="../assets/Logos/ADIDAS.png"
                    className="d-inline-block align-top slide-pic"
                    alt="adidas logo"
                />

                <img
                    src="../assets/Logos/ASOS.png"
                    className="d-inline-block align-top slide-pic"
                    alt="asos logo"
                />

                <img
                    src="../assets/Logos/BUDWEISER.png"
                    className="d-inline-block align-top slide-pic"
                    alt="budweiser logo"
                />

                <img
                    src="../assets/Logos/JUST_EAT.png"
                    className="d-inline-block align-top slide-pic"
                    alt="just eat logo"
                />

                <img
                    src="../assets/Logos/KLARNA.png"
                    className="d-inline-block align-top slide-pic"
                    alt="klarna logo"
                />

                <img
                    src="../assets/Logos/LAYS.png"
                    className="d-inline-block align-top slide-pic"
                    alt="lays logo"
                />

                <img
                    src="../assets/Logos/MASTER_CARD.png"
                    className="d-inline-block align-top slide-pic"
                    alt="master card logo"
                />

                <img
                    src="../assets/Logos/PEPSI.png"
                    className="d-inline-block align-top slide-pic"
                    alt="pepsi logo"
                />

                <img
                    src="../assets/Logos/TIKTOK.png"
                    className="d-inline-block align-top slide-pic"
                    alt="tiktok logo"
                />

                <img
                    src="../assets/Logos/VISA.png"
                    className="d-inline-block align-top slide-pic"
                    alt="visa logo"
                />

            </Marquee>
        </div>
    );
};