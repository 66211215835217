import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-scroll';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import BrandForm from './brand-form';
import CreatorForm from './creator-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function NavMenu() {
    const [open, setOpen,] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [fix, setFix] = useState(false);

    function setFixed() {
        if (window.scrollY >= 3500) {
            setFix(true);
        } else {
            setFix(false);
        }
    }
    window.addEventListener('scroll', setFixed);
    return (
        <div>
            <CssBaseline />
            <Navbar expand="lg" className="nav fixed" id="navbar">
                <Container>
                    <Link to="hero" spy={true} smooth={true} className='link'><Navbar.Brand>
                        <img
                            src="../assets/curva-logo.png"
                            width="200"
                            height="31"
                            className="d-inline-block align-top"
                            alt="Curva logo"
                        />

                    </Navbar.Brand></Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-left" id="navmenu">
                            <Link to="what" offset={-140} spy={true} smooth={true}> <Nav.Link>What we do</Nav.Link></Link>
                            <Link to="work" spy={true} smooth={true}> <Nav.Link>Work</Nav.Link></Link>
                            <Link to="cons" spy={true} smooth={true}> <Nav.Link>Contact</Nav.Link></Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className={fix ? 'nav-cta fixed' : 'nav-cta'}>
                <Button variant="contained" onClick={handleOpen} className="cta-nav safari_only">brands</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} style={{ textAlign: 'center' }}>
                        <div onClick={handleClose} className="close"></div>
                        <Typography id="modal-modal-title" variant="h3" component="h3">
                            I am a brand
                        </Typography>
                        <BrandForm />
                        <p style={{ paddingTop: '20px', fontSize: '12px' }}>By clicking submit you are consenting for COPA90 to store your data inline the <a href="https://www.copa90.com/privacy-policy" target='blank'>Privacy Policy</a></p>
                    </Box>
                </Modal>
                <Button variant="contained" onClick={handleOpen1} className="cta-nav safari_only">creators</Button>
                <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} style={{ textAlign: 'center' }}>
                        <div onClick={handleClose1} className="close"></div>
                        <Typography id="modal-modal-title" variant="h3" component="h3">
                            I am a creator
                        </Typography>
                        <CreatorForm />
                        <p style={{ paddingTop: '20px', fontSize: '12px' }}>By clicking submit you are consenting for COPA90 to store your data inline the <a href="https://www.copa90.com/privacy-policy" target='blank'>Privacy Policy</a></p>
                    </Box>
                </Modal>
            </div>

        </div>
    );
}

export default NavMenu;